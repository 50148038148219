import React, { useState } from "react";
import styles from "./Contact.module.css";
import person from "../img/sales.jpg";
import emailjs from "emailjs-com";
import EmailConfirmation from "../minicomponents/EmailConfirmation";

const Contact = () => {
  const [displayConfirm, setDisplayConfirm] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_MESAJ_SERVICE,
        process.env.REACT_APP_MESAJ_TEMPLATE,
        e.target,
        process.env.REACT_APP_MESAJ_KEY
      )
      .then(() => setDisplayConfirm(true))
      .catch((error) => alert(error));
    e.target.reset();
  };

  return (
    <div>
      <div className={styles.main} onClick={() => setDisplayConfirm(false)}>
        {displayConfirm && (
          <EmailConfirmation
            className={styles.confirmBox}
            setDisplayConfirm={setDisplayConfirm}
          />
        )}
        <div className={styles.leftSide}>
          <h1 className={styles.p}>... Lass uns in Kontakt treten</h1>
          <p className={styles.p}>
            Wenn Sie Fragen zum Kauf oder Verkauf eines Auto, LKW oder anderen
            Baumaschinen haben, schreiben Sie an unser qualifiziertes Personal.
          </p>

          <div className={styles.minicard}>
            <img src={person} alt="sales person" />
            <div className={styles.minicardName}>
              <h1 className={styles.salesPersonName}>Justo Blazquez</h1>
              <p className={styles.p}>Verkaufsleiter</p>
            </div>
          </div>

          <p className={styles.p}>
            Sie können Ihre Fragen an unser Team in folgenden Sprachen stellen:
            Deutsch, English, Italiano, Polski, Español, Pусский
          </p>
          <p className={styles.p1}>
            MÜHLWEG 3 , <br /> 67105 SCHIFFERSTADT <br /> Deutschland
          </p>
          <p className={styles.p1}>
            Tel: +49 (0)172 6299077
            <br />
            Tel: +49 (0)171 4744972
            <br />
            Mobiltelefon: +49 (0)172 6299077
          </p>
        </div>

        <form className={styles.rightSide} onSubmit={sendEmail}>
          <h1 className={styles.p}>In Kontakt kommen!</h1>
          <p className={styles.p}>
            Senden Sie uns eine E-Mail mit Ihren Kontaktdaten und Ihrer
            Nachricht und wir melden uns schnellstmöglich bei Ihnen!
          </p>
          <p className={styles.p1}>Name</p>
          <input type="text" placeholder="Name" name="name" required />

          <p className={styles.p1}>Telefonnummer</p>
          <input
            type="text"
            placeholder="Telefonnummer"
            required
            name="phone"
          />

          <p className={styles.p1}>Email</p>
          <input type="email" placeholder="Email" name="user_email" required />

          <p className={styles.p1}>Message</p>
          <textarea
            name="message"
            id=""
            cols="30"
            rows="4"
            placeholder="Ihre nachricht hier"
          />

          <button>Senden</button>
        </form>
      </div>
      <div className={styles.copyright}>
        <p>&copy; Blape Camion GMBH. All Rights Reserved.</p>
        <p>Web services provided by Coman Marian: maryangal03@gmail.com</p>
      </div>
    </div>
  );
};

export default Contact;
