import React, { useState, useEffect, Suspense } from "react";
import styles from "./Products.module.css";
import { FaTruckMoving } from "react-icons/fa";
import { FaTruckLoading } from "react-icons/fa";
import { AiOutlineCar } from "react-icons/ai";
import { FaTrailer } from "react-icons/fa";
import { FaShuttleVan } from "react-icons/fa";
import { CgComponents } from "react-icons/cg";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { GiForklift } from "react-icons/gi";
import { GiBus } from "react-icons/gi";
import { GiTruck } from "react-icons/gi";
import { GiFarmTractor } from "react-icons/gi";
import { BsTruck } from "react-icons/bs";
import { db, auth } from "../services/firebase";
import finish from "../img/flag.svg";
import excavator from "../img/excavator.svg";
import ReactPaginate from "react-paginate";
import Loader from "../minicomponents/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import agent from "../img/saleAgent.png";

const Product = React.lazy(() => import("./Product"));

const Products = ({ fileUrl }) => {
  const [cars, setCars] = useState([]);

  // const [bau , setBau] = useState(cars)
  const [email, setEmail] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [underline, setUnderline] = useState(false);
  const [lkwUnderline, setLkwUndeline] = useState(false);
  const [pkwUnderline, setPkwUndeline] = useState(false);
  const [anhangerUnderline, setAnhangerUndeline] = useState(false);
  const [teileUnderline, setTeileUndeline] = useState(false);

  const [showLkwTypes, setShowLkwTypes] = useState(false);

  const [verified, setVerified] = useState(false);

  const [message, setMessage] = useState(
    "Bitte wählen Sie eine Kategorie, um loszulegen !"
  );

  // paginate
  const [pageNumber, setPageNumber] = useState(0);
  const carsPerPage = 10;
  const pagesVisited = pageNumber * carsPerPage;

  const displayCars = cars
    .slice(pagesVisited, pagesVisited + carsPerPage)
    .map((car) => {
      return (
        <Suspense fallback={<Loader />} key={Math.random() * 1000}>
          <Product
            car={car}
            key={Math.random() * 1000}
            currentUser={currentUser}
            fileUrl={fileUrl}
          />
        </Suspense>
      );
    });

  const pageCount = Math.ceil(cars.length / carsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // end paginate

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        setCurrentUser(uid);
        const { photoURL, displayName, email } = user;
        setEmail(email);
        setPhotoURL(photoURL);
        setDisplayName(displayName);
      } else {
        setEmail(null);
        setPhotoURL(null);
        setDisplayName(null);
      }
    });
  }, []);

  const getAllCarsData = async () => {
    db.collection("cars").onSnapshot((snapshot) => {
      let cars = [];
      snapshot.forEach((car) => {
        let data = { ...car.data(), id: car.id };
        cars.push(data);
      });
      setCars(cars);
      setUnderline(true);
    });
    setShowLkwTypes(false);
    setLkwUndeline(false);
    setPkwUndeline(false);
    setTeileUndeline(false);
    setAnhangerUndeline(false);
  };

  const getLkwDataHandler = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("type", "==", "LKW")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setShowLkwTypes(!showLkwTypes);

    setUnderline(false);
    setLkwUndeline(true);
    setPkwUndeline(false);
    setTeileUndeline(false);
    setAnhangerUndeline(false);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getPkwDataHandler = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("type", "==", "PKW")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);
    setShowLkwTypes(false);
    setUnderline(false);
    setUnderline(false);
    setLkwUndeline(false);
    setPkwUndeline(true);
    setTeileUndeline(false);
    setAnhangerUndeline(false);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getTeileDataHandler = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("type", "==", "Teile")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);
    setShowLkwTypes(false);
    setUnderline(false);
    setLkwUndeline(false);
    setPkwUndeline(false);
    setTeileUndeline(true);
    setAnhangerUndeline(false);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getTrailerHandler = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("type", "==", "Anhänger")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setShowLkwTypes(false);
    setCars(sortedCars);
    setUnderline(false);
    setLkwUndeline(false);
    setPkwUndeline(false);
    setTeileUndeline(false);
    setAnhangerUndeline(true);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getBis7 = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "LKW und Trasporter bis 7.5Tonne")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getNach7 = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "LKW Uber 7.5Tonne")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getSattelzugmaschinen = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Sattelzugmaschinen")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getAuflieger = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Auflieger")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getBaumaschinen = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Baumaschinen")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getBusse = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Busse")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getAgrarfahzeuge = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Agrarfahzeuge")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const getStapler = async () => {
    let sortedCars = [];
    await db
      .collection("cars")
      .where("subtypes", "==", "Stapler")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sortedCars.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setCars(sortedCars);

    setMessage(
      sortedCars.length ? "" : "Neue Produkte werden in Kürze hinzugefügt !"
    );
  };

  const onChangeChaptchaHandler = () => {
    setVerified(true);
  };

  return (
    <div>
      {!verified && (
        <div className={styles.chaptcha}>
          <ReCAPTCHA
            sitekey="6LfkKUsdAAAAAJdvr6mkqKHZ4kcbv5yewZrYKPtQ"
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={onChangeChaptchaHandler}
          />
          <h3>Ich bin kein Roboter !</h3>
        </div>
      )}
      {verified && (
        <div className={styles.master}>
          <div className={styles.categories}>
            {!cars.length && (
              <img
                className={styles.agent}
                src={agent}
                alt="sales agent at blape camion company in schifferstadt germany"
              />
            )}
            <div className={cars.length ? styles.insideDiv : styles.insideDiv2}>
              <p onClick={getAllCarsData}>
                <AiOutlineCodeSandbox id="all" className={styles.lkw} />
                <label
                  className={underline ? styles.activeLabel : styles.label}
                >
                  Alle
                </label>
              </p>

              <p onClick={getLkwDataHandler}>
                <FaTruckMoving id="lkw" className={styles.lkw} />
                <label
                  className={lkwUnderline ? styles.activeLabel : styles.label}
                >
                  LKW
                </label>
              </p>

              <p onClick={getPkwDataHandler}>
                <AiOutlineCar id="pkw" className={styles.lkw} />
                <label
                  className={pkwUnderline ? styles.activeLabel : styles.label}
                >
                  PKW
                </label>
              </p>

              <p onClick={getTrailerHandler}>
                <FaTrailer id="anhanger" className={styles.lkw} />
                <label
                  className={
                    anhangerUnderline ? styles.activeLabel : styles.label
                  }
                >
                  Anhänger
                </label>
              </p>

              <p onClick={getTeileDataHandler}>
                <CgComponents id="teile" className={styles.lkw} />
                <label
                  className={teileUnderline ? styles.activeLabel : styles.label}
                >
                  Teile
                </label>
              </p>
            </div>
            <div>
              {showLkwTypes && (
                <div className={styles.subtypes}>
                  <div
                    className={styles.subsubTypes}
                    onClick={() => setShowLkwTypes(false)}
                  >
                    <div className={styles.miniSubtypes} onClick={getBis7}>
                      <FaShuttleVan className={styles.icons} />
                      <p>Transporter und Lkw Bis 7.5Tonne </p>
                    </div>
                    <div className={styles.miniSubtypes} onClick={getNach7}>
                      <BsTruck className={styles.icons} />
                      <p>LKW Uber 7.5Tonne</p>
                    </div>
                    <div
                      className={styles.miniSubtypes}
                      onClick={getSattelzugmaschinen}
                    >
                      <GiTruck className={styles.icons} />
                      <p>Sattelzugmaschinen</p>
                    </div>
                    <div className={styles.miniSubtypes} onClick={getAuflieger}>
                      <FaTruckLoading className={styles.icons} />
                      <p>Auflieger</p>
                    </div>
                    <div
                      className={styles.miniSubtypes}
                      onClick={getBaumaschinen}
                    >
                      <img
                        className={styles.excavator}
                        src={excavator}
                        alt="excavator for sale in schifferstadt germany from blape company"
                      />
                      <p>Baumaschinen</p>
                    </div>
                    <div className={styles.miniSubtypes} onClick={getBusse}>
                      <GiBus className={styles.icons} />
                      <p>Busse</p>
                    </div>
                    <div
                      className={styles.miniSubtypes}
                      onClick={getAgrarfahzeuge}
                    >
                      <GiFarmTractor className={styles.icons} />
                      <p>Agrarfarhzeuge</p>
                    </div>
                    <div className={styles.miniSubtypes} onClick={getStapler}>
                      <GiForklift className={styles.icons} />
                      <p>Stapler</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.results}>
            {cars.length === 0 ? (
              <p className={styles.welcomeScreen}>{message}</p>
            ) : (
              displayCars
            )}
          </div>
          {cars.length && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={styles.paginationButtons}
              previousLinkClassName={styles.previousButton}
              nextLinkClassName={styles.nextButton}
              activeClassName={styles.activeButton}
            />
          )}
          {cars.length && (
            <div>
              <img
                src={finish}
                className={styles.flag}
                alt="end of results flag"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Products;
