import React, { Suspense } from "react";
import styles from "./MainPage.module.css";
import { Link } from "react-router-dom";
import Footer from "../minicomponents/Footer";
import Slideshow from "../minicomponents/Slideshow";
import Loader from "../minicomponents/Loader";
import { FiChevronsRight } from "react-icons/fi";

const Newest = React.lazy(() => import("../minicomponents/Newest"));

const MainPage = ({ cars }) => {
  return (
    <div className={styles.mainPage}>
      <div className={styles.heroSection}>
        <div className={styles.hero}>
          <h1>BLAPE CAMION GMBH NUTZFAHRZEUGE</h1>
          <div className={styles.importExport}>
            <span>
              <FiChevronsRight />
              ankauf
            </span>

            <span>
              <FiChevronsRight />
              verkauf
            </span>

            <span>
              <FiChevronsRight />
              import
            </span>

            <span>
              <FiChevronsRight />
              export
            </span>
          </div>
          <div className={styles.buttonsContainer}>
            <Link to="/products">
              <button className={styles.firstBtn}>Fahrzeugen Suchen</button>
            </Link>

            <Link to="/contact">
              <button className={styles.secondBtn}>Kontakt</button>
            </Link>
          </div>
        </div>
        <div className={styles.offersParent}>
          <Suspense fallback={<Loader/>}>
            <Newest cars={cars}/>
          </Suspense>
        </div>
      </div>
      <div className={styles.truckSlideshow}>
        <Slideshow />
      </div>
      <Footer cars={cars} />
    </div>
  );
};

export default MainPage;
