import React from "react";
import styles from "./EmailConfirmation.module.css";

const EmailConfirmation = ({setDisplayConfirm}) => {
  return (
    <>
      <div className={styles.confirmationParent}>
        <div className={styles.checkmarkCircle}>
          <div className={styles.background}></div>
          <div className={`${styles.checkmark} ${styles.draw} `}></div>
        </div>
        <h2>Email sent!</h2>
        <button onClick = {()=> setDisplayConfirm(false)} >Close</button>
      </div>
    </>
  );
};

export default EmailConfirmation;
