import React from "react";
import styles from "./Slideshow.module.css";
import img1 from "../img1.jpeg";
import img2 from "../img2.jpeg";
import img3 from "../img3.jpeg";
import img4 from "../img4.jpeg";
import img5 from "../img5.jpeg";
import img6 from "../img6.jpeg";
import img7 from "../img7.jpeg";
import img8 from "../img8.jpeg";

const Slideshow = () => {
  return (
    <div className={styles.slider}>
      <div className={styles.slideTrack}>
        <div className={styles.slide}>
          <img src={img1} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img2} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img3} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img4} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img5} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img6} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img7} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img8} alt = "images of a truck in schifferstadt germany"/>
        </div>


        {/* copy */}
        <div className={styles.slide}>
          <img src={img1} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img2} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img3} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img4} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img5} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img6} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img7} alt = "images of a truck in schifferstadt germany"/>
        </div>
        <div className={styles.slide}>
          <img src={img8} alt = "images of a truck in schifferstadt germany" />
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
