import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/Nav";
import MainPage from "./components/MainPage";
import Products from "./components/Products";
import Contact from "./components/Contact";
import Admin from "./components/Admin";
import { auth, storage, db } from "./services/firebase";
import SimpleReactLightbox from "simple-react-lightbox";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [cars, setCars] = useState([]);

  const [fileUrl, setFileUrl] = useState([]);
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);

  const [lkws, setLkws] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        setCurrentUser(uid);
      }
    });
  }, []);

  // get time
  useEffect(() => {
    getTimes();
  }, []);

  const getTimes = async () => {
    let lkws = [];
    await db
      .collection("cars")
      .where("type", "==", "LKW")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          lkws.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("error getting documents", error);
      });
    setLkws(lkws);
  };

  //  end get time

  //file upload/////////////////
  const handleChange = async (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleUpload = (e) => {
    const promises = [];
    images.map((image) => {
      e.preventDefault();
      const uploadTask = storage.ref(`images/${image.name}`).put(image);

      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {
              setFileUrl((prevState) => [...prevState, urls]);
            });
          setProgress(0);
        }
      );
    });
    Promise.all(promises)
      .then(() => alert("Alle Bilder wurden hochgeladen."))
      .catch((error) =>
        alert("Es ist ein Fehler aufgetreten, versuchen Sie es später erneut!")
      );
  };

  // get cars for footer
  useEffect(() => {
    db.collection("cars")
      // .get().then() => nu e in timp real , aici facem subscribe la database si stergem sau adaugam pe loc in fronend
      .get()
      .then((snapshot) => {
        let cars = [];
        snapshot.docs.forEach((car) => {
          let data = { ...car.data(), id: car.id };
          cars.push(data);
        });
        setCars(cars);
      });
  }, []);
  // ///////////////////

  return (
    <SimpleReactLightbox>
      <Router>
        <div>
          <Nav />
          <Routes>
            <Route exact path="/" element={<MainPage cars={cars} />} />
            <Route path="/products" fileUrl={fileUrl} element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/admin"
              element={
                <Admin
                  currentUser={currentUser}
                  handleChange={handleChange}
                  handleUpload={handleUpload}
                  progress={progress}
                  fileUrl={fileUrl}
                  images={images}
                />
              }
            />
          </Routes>
        </div>
      </Router>
    </SimpleReactLightbox>
  );
}

export default App;
