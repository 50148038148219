import React, { useState } from "react";
import styles from "./Nav.module.css";
import { Link, NavLink } from "react-router-dom";
import { BsKey } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Nav = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <nav>
      <div className={styles.brandTitle}>
        <h1>
          Bl<span>A</span>pe.
        </h1>
        <div className={styles.logos}>
          <a className={styles.whatsapp} href = "https://wa.me/00491726299077" target = "_blank" rel="noreferrer"><BsWhatsapp  /></a>
          <a className={styles.whatsapp} href = "https://wa.me/00491714744972" target = "_blank" rel="noreferrer"><BsWhatsapp  /></a>
          <a className={styles.whatsapp}  href = "https://www.facebook.com/Blape-camion-Gmbh-414586202667486" target = "_blank" rel="noreferrer"><FaFacebookF /></a>
        </div>
      </div>
      <button
        className={styles.toggleMenu}
        onClick={() => setIsActive(!isActive)}
      >
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
      </button>
      <div className={ !isActive ? styles.navbarLinks : styles.navbarLinksActive}>
        <ul className = {styles.navbarLinksUl} >
          <li>
            <NavLink end className={({isActive}) => isActive ?  `${styles.active}` : ''} to="/" onClick = {() => setIsActive(false)} >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className={({isActive}) => isActive ?  `${styles.active}` : ''} to="/products" onClick = {() => setIsActive(false)}>
              Produkte
            </NavLink>
          </li>
          <li>
            <NavLink className={({isActive}) => isActive ?  `${styles.active}` : ''} to="/contact" onClick = {() => setIsActive(false)}>
              Kontakt
            </NavLink>
          </li>
          <li>
            <Link to="/admin" onClick = {() => setIsActive(false)}>
              <BsKey className={styles.cheieAdmin} />
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
