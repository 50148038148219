import React, { useState } from "react";
import style from "./Admin.module.css";
import { db, auth } from "../services/firebase";

const Admin = ({
  currentUser,
  handleChange,
  handleUpload,
  fileUrl,
  progress,
  images,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("");

  // lkw types

  const [subtypes, setSubtypes] = useState("");

  const [trasporterBis7Tonne, setTrasporterBis7Tonne] = useState("");
  const [lkwUber7Tonne, setLkwUber7Tonne] = useState("");
  const [sattelzugmaschinen, setSatelzugmaschinen] = useState("");
  const [auflieger, setAuflieger] = useState("");
  const [baumaschinen, setBaumaschinen] = useState("");
  const [busse, setBusse] = useState("");
  const [agrarfahzeuge, setAgrarfahrzeuge] = useState("");
  const [stapler, setStapler] = useState("");

  // end lkw types

  const [carName, setCarName] = useState("");
  const [carPrice, setCarPrice] = useState("");
  const [firstRegistration, setFirstRegistration] = useState("");
  const [constructionYear, setConstructionYear] = useState("");
  const [km, setKm] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [pollutionCat, setPollutionCat] = useState("");
  const [pollutionColor, setPollutionColor] = useState("");
  const [weight, setWeight] = useState("");
  const [carColor, setCarColor] = useState("");
  const [carDescription, setCarDescription] = useState("");
  const [errorByLogIn, setErrorByLogIn] = useState("");

  const [typeIsValid, setTypeIsValid] = useState(true);
  const [carNameIsValid, setCarNameIsValid] = useState(true);
  const [carPriceIsValid, setCarPriceIsValid] = useState(true);
  const [firstRegistrationIsValid, setFirstRegistrationIsValid] =
    useState(true);
  const [constructionYearIsValid, setConstructionYearIsValid] = useState(true);
  const [kmIsValid, setKmIsValid] = useState(true);
  const [weightIsValid, setWeightIsValid] = useState(true);

  const signin = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {})
      .catch((error) => {
        setErrorByLogIn("Invalid Username / Password , try again!");
      });
    setEmail("");
    setPassword("");
  };

  function refreshPage() {
    window.location.reload(true);
  }

  const signOut = (e) => {
    auth
      .signOut()
      .then(() => {
        refreshPage();
      })
      .catch((error) => {
        console.log(error, "Error logging out");
      });
  };

  const addCarHandler = async (e) => {
    e.preventDefault();
    if (
      !type ||
      !carName ||
      !carPrice ||
      !firstRegistration ||
      !constructionYear ||
      !km ||
      !weight
    ) {
      setTypeIsValid(false);
      setCarNameIsValid(false);
      setCarPriceIsValid(false);
      setFirstRegistrationIsValid(false);
      setConstructionYearIsValid(false);
      setKmIsValid(false);
      setWeightIsValid(false);
      return;
    }
    setTypeIsValid(true);
    setCarNameIsValid(true);
    setCarPriceIsValid(true);
    setFirstRegistrationIsValid(true);
    setConstructionYearIsValid(true);
    setKmIsValid(true);
    setWeightIsValid(true);

    await db.collection("cars").add({
      addedOn: Date.now(),
      fileUrl,
      type,
      subtypes,
      carName,
      carPrice,
      firstRegistration,
      constructionYear,
      km,
      gearbox,
      pollutionCat,
      pollutionColor,
      weight,
      carColor,
      carDescription,
    });
    refreshPage();
  };

  return (
    <div className={style.masterAdmin}>
      {currentUser ? (
        <div className={style.adminArea}>
          <h1 className={style.adminH1}>Admin-Bereich</h1>

          <form className={style.adminForm}>
            {/* file upload*/}
            <div className={style.imageContainer}>
              <input type="file" multiple onChange={handleChange} />
              <progress value={progress} max="100" />
            </div>
            {fileUrl && (
              <button className={style.uploadButton} onClick={handleUpload}>
                Upload
              </button>
            )}
            {images ? (
              images.map((img, i) => <p key={i}>{img.name}</p>)
            ) : (
              <p>Bilder werden in Kürze hinzugefügt!</p>
            )}
            {/* end file upload */}
            <div className={style.adminSubform}>
              <div className={style.firstSide}>
                <p>
                  (!) Kategorie
                  <select
                    type="text"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                  >
                    <option>Wahlen Sie ein option!</option>
                    <option>LKW</option>
                    <option>PKW</option>
                    <option>Anhänger</option>
                    <option>Teile</option>
                  </select>
                </p>
                {!typeIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}

                <p>
                  (!) LKW Typ
                  <select
                    type="text"
                    value={subtypes}
                    onChange={(e) => setSubtypes(e.target.value)}
                    required
                  >
                    <option>Wahlen Sie ein option!</option>
                    <option>LKW und Trasporter bis 7.5Tonne</option>
                    <option>LKW uber 7.5Tonne</option>
                    <option>Sattelzugmaschinen</option>
                    <option>Auflieger</option>
                    <option>Baumaschinen</option>
                    <option>Busse</option>
                    <option>Agrarfarhzeuge</option>
                    <option>Stapler</option>
                  </select>
                </p>

                <p>
                  Fahrzeug Name:
                  <input
                    type="text"
                    name="name"
                    value={carName}
                    onChange={(e) => {
                      setCarName(e.target.value);
                    }}
                    required
                  />
                </p>
                {!carNameIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}

                <p>
                  Fahrzeug Preis
                  <input
                    type="text"
                    value={carPrice}
                    onChange={(e) => setCarPrice(e.target.value)}
                  />
                </p>
                {!carPriceIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}

                <p>
                  Erstzulassung
                  <input
                    type="text"
                    value={firstRegistration}
                    onChange={(e) => setFirstRegistration(e.target.value)}
                  />
                </p>

                {!firstRegistrationIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}

                <p>
                  Baujahr
                  <input
                    type="text"
                    value={constructionYear}
                    onChange={(e) => setConstructionYear(e.target.value)}
                  />
                </p>

                {!constructionYearIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}
              </div>
              <div className={style.secondSide}>
                <p>
                  Kilometer
                  <input
                    type="text"
                    value={km}
                    onChange={(e) => setKm(e.target.value)}
                  />
                </p>

                {!kmIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}

                <p>
                  Getriebe
                  <select
                    type="text"
                    value={gearbox}
                    onChange={(e) => setGearbox(e.target.value)}
                  >
                    <option>Wahlen Sie ein option</option>
                    <option>Automatik</option>
                    <option>Halbautomatik</option>
                    <option>Schaltgetriebe </option>
                  </select>
                </p>

                <p>
                  Schadstoffklasse
                  <select
                    type="text"
                    value={pollutionCat}
                    onChange={(e) => setPollutionCat(e.target.value)}
                  >
                    <option>Wahlen Sie ein option</option>
                    <option>Euro 6</option>
                    <option>Euro 5</option>
                    <option>Euro 4</option>
                    <option>Euro 3</option>
                    <option>Euro 2</option>
                    <option>Euro 1</option>
                  </select>
                </p>
                <p>
                  Umweltplakette
                  <select
                    type="text"
                    value={pollutionColor}
                    onChange={(e) => setPollutionColor(e.target.value)}
                  >
                    <option>Wahlen sie ein option</option>
                    <option>Blau</option>
                    <option>Rot</option>
                    <option>Gelb</option>
                    <option>Grun</option>
                  </select>
                </p>

                <p>
                  Gesamtgewicht
                  <input
                    type="text"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </p>

                {!weightIsValid && (
                  <p style={{ color: "red" }}>Bitte einen Wert eingeben!</p>
                )}
                <p>
                  Farbe
                  <input
                    type="text"
                    value={carColor}
                    onChange={(e) => setCarColor(e.target.value)}
                  />
                </p>
              </div>
            </div>
            <p className={style.descriptionPara}>
              Bezeichnung(1000 Zeichen erlaubt)
            </p>
            <textarea
              className={style.adminTextarea}
              type="text"
              rows="3"
              maxLength="1000"
              value={carDescription}
              onChange={(e) => setCarDescription(e.target.value)}
            />
            <button onClick={addCarHandler}>Anzeige einfügen</button>
            <button className={style.loginButton} onClick={signOut}>
              signOut
            </button>
          </form>
        </div>
      ) : (
        <div className={style.loginForm}>
          <form>
            <p className={style.loginPara}>Username</p>
            <input
              className={style.loginInputs}
              required={true}
              type="text"
              placeholder="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className={style.loginPara}>Password</p>
            <input
              className={style.loginInputs}
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className={style.loginButtons} onClick={signin}>
              Sign In
            </button>
            <p>{errorByLogIn}</p>
          </form>
        </div>
      )}
    </div>
  );
};

export default Admin;
