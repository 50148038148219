import React from "react";
import CookieConsent from "react-cookie-consent";



const Footer = ({cars}) => {
  return (
    <div>
      <footer>
        <CookieConsent
          style={{
            backgroundColor: "black",
            height: "80px",
            textAlign: "left",
            color: "yellow",
            fontSize: "1.4rem",
            letterSpacing : '3px'
          }}
          buttonStyle={{
            color: "yellow",
            backgroundColor: "transparent",
            border: "2px solid #0099ff",
            fontSize : '1.4rem',
            letterSpacing : '3px'
          }}
        >
          Diese Website verwendet Cookies
        </CookieConsent>
      </footer>
    </div>
  );
};

export default Footer;
